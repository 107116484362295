<template>
    <VuePureLightbox class="lightbox">
    <section class="beta has-text-centered">
        <div class="container">
            <table class="reg-form has-text-centered">
            <tr>
                <td class="form-header">
                    <p class="org-description is-size-5"></p>
                </td>
            </tr>
            <tr>
                <td class="form-content">
                    <!-- Begin Mailchimp Signup Form -->
                    <div id="mc_embed_signup">
                    <form action="https://instacrypt.us2.list-manage.com/subscribe/post?u=d0b6e48e7564b974e1ed0f95f&amp;id=2e43e00014" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>
                        <div id="mc_embed_signup_scroll">
                          <h2>Sign-Up here for a BETA invite when it's ready!</h2>
                        <div class="indicates-required"><span class="asterisk">*</span> indicates required</div>
                        <div class="mc-field-group">
                          <label for="mce-EMAIL">Email Address  <span class="asterisk">*</span>
                        </label>
                          <input type="email" value="" name="EMAIL" class="required email" id="mce-EMAIL">
                        </div>
                        <div class="mc-field-group">
                          <label for="mce-FNAME">First Name </label>
                          <input type="text" value="" name="FNAME" class="" id="mce-FNAME">
                        </div>
                        <div class="mc-field-group">
                          <label for="mce-LNAME">Last Name </label>
                          <input type="text" value="" name="LNAME" class="" id="mce-LNAME">
                        </div>
                        <div id="mce-responses" class="clear">
                          <div class="response" id="mce-error-response" style="display:none"></div>
                          <div class="response" id="mce-success-response" style="display:none"></div>
                        </div>    <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
                          <div style="position: absolute; left: -5000px;" aria-hidden="true"><input type="text" name="b_d0b6e48e7564b974e1ed0f95f_2e43e00014" tabindex="-1" value=""></div>
                          <div class="clear"><input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" class="button"></div>
                        </div>
                    </form>
                    </div>
                    <!--End mc_embed_signup-->
                    <br>
                    <br>
                </td>
            </tr>
            </table>
        </div>
    </section>
    </VuePureLightbox>
</template>

<script>
import User from '../models/user';

export default {
  name: 'beta',
  props: {
      plan: {
          type: String,
          default: "0"
      },
  },
  data() {
    return {
      user: new User('', ''),
      loading: false,
      message: '',
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    }
  },
  created() {
    if (this.loggedIn) {
      console.log(this.next);
      this.$router.push('/dashboard/' + this.next);
    }
  }
};
</script>

<style lang="scss">
    $main-color: #aac734;
    .lightbox {
        width: 100%; height: 100%;
        background: rgba(0, 0, 0, .4);
    }
    .beta {
        padding-top: 0px; // 55px;
        padding-bottom: 100px;
        width: 100%;
    }
    .container {
        width: 100%;
        justify-content: center;
    }
    .submit {
        margin-top: 30px;
        text-align: center;
    }
    .reg-form {
        width: 100%;
        min-width: 450px;
        max-width: 100%;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        box-shadow: 0px 2px 30px rgba(0,0,0,0.3);
        margin-left: auto;
        margin-right: auto;
        margin-top: 0px; // 40px;
    }
    .form-header {
        font-weight: bold;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        padding: 20px;
        background: $main-color;
    }
    .form-content {
        background: #F5F5F5;
        padding: 30px;
        padding-left: 10%;
        padding-right: 10%;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
    }
    .form-content .label {
        font-size: 0.78rem;
        text-align: left;
        padding-left: 5px;
        font-weight: 400;
    }
    .error {
        padding-top: 10px;
        color: red;
    }
</style>
